@import "config";

.table {
  .row {
    > div {
      min-height: 4rem;
    }
    .head {
      min-height: auto;
    }
  }
  .nameCell {
    flex: 10;
    width: 10px;
    padding-left: $contentDefaultIndent * 2;
    text-align: left;
  }
  .iconCell {
    flex: 4;
    width: 4px;
    text-align: left;
    a {
      color: inherit;
      text-decoration: none;
      button {
        width: 2rem;
        height: 2rem;
        margin-right: $contentDefaultIndent;
        padding: 0;
        color: $uiWhiteColor;
        svg {
          font-size: 1rem;
        }
      }
    }
  }
  .activityCell {
    flex: 10;
    width: 10px;
    text-align: left;
  }
}
