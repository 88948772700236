@import "config";

.summaryFilter {
  width: 280px;
  > form {
    display: flex;
    align-items: center;
    height: 100%;
    > div {
      height: 100%;
      button {
        padding: 0 0.7em;
        &:global(.btn) {
          &-secondary {
            &[disabled] {
              border-color: $uiBorderColor;
              background: $uiBorderColor;
              color: $uiDarkColor;
            }
          }
        }
      }
    }
  }
}
