@import "config";

.summaryPage {
  padding: 0 1em !important;
  overflow: auto;
  .pageHeader {
    display: flex;
    align-items: center;
    height: $pageHeaderHeight;
    min-height: $pageHeaderHeight;
    font-weight: 500;
    .leftBlock {
      min-width: 0;
      margin-right: $contentDefaultIndent * 2;
      .subTitle {
        opacity: 0.5;
        color: $uiDarkColor;
        font-size: 0.8em;
        letter-spacing: 0.1em;
        text-transform: uppercase;
      }
      .title {
        display: flex;
        align-items: center;
        overflow: hidden;
        font-size: 1.6em;
        line-height: 1.2;
        white-space: nowrap;
      }
    }
    .rightBlock {
      display: flex;
      flex: auto;
      justify-content: flex-end;
      > div {
        + div {
          margin-left: $contentDefaultIndent;
        }
      }
      .connectInboxButton {
        &:not(:hover):not(:active) {
          background-color: #e3fbff;
        }
      }
      .setupBackupButton {
        img {
          width: 1.2em;
          margin-right: 0.3em;
        }
      }
    }
  }
  .card {
    display: flex;
    flex: auto;
    flex-direction: column;
    border-radius: $contentDefaultIndent / 2;
    background-color: $uiWhiteColor;
  }
}
