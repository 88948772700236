@import "config";

.nameCell {
  a {
    display: flex;
    position: relative;
    flex: auto;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-left: 2rem;
    color: inherit;
    text-decoration: none;
  }
  img {
    position: absolute;
    top: 50%;
    left: 0;
    width: 1.5rem;
    max-width: 1.5rem;
    margin-right: $contentDefaultIndent;
    transform: translate(0, -50%);
  }
  .content {
    width: 100%;
    white-space: nowrap;
  }
  .name {
    overflow: hidden;
    font-size: 0.875rem;
    font-weight: 500;
    text-overflow: ellipsis;
  }
}
