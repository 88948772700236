@import "config";

.activityCell {
  .content {
    display: flex;
    flex: auto;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: inherit;
    text-decoration: none;
    .text {
      width: 100%;
      white-space: nowrap;
    }
    .date {
      color: $uiNeutralColor;
    }
    .tooltip {
      > div {
        max-width: 100%;
      }
      .tooltipClassName {
        right: 0;
        left: auto;
        max-width: 100%;
      }
    }
  }
}
